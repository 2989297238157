import * as Charts from "./chart";

let frappe = {};

frappe.NAME = "Frappe Charts";
frappe.VERSION = "1.6.2";

frappe = Object.assign({}, frappe, Charts);

export default frappe;
